import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"flat":"","color":"grey lighten-5","dense":""}},[_c(VToolbarTitle,[_vm._v("Reportes")])],1),_c(VDivider),_c(VList,{attrs:{"flat":""}},[_c(VListItem,[_c(VListItemAction,[_c(VBtn,{attrs:{"color":"green darken-1","x-small":"","dark":""},on:{"click":function($event){_vm.mostrarReporteAlumnosxmateria = true}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-file-excel")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Alumnos por materia")])],1)],1)],1)],1),(_vm.mostrarReporteAlumnosxmateria)?_c('alumnosxmateriaComponent',{attrs:{"mostrar":_vm.mostrarReporteAlumnosxmateria,"idEscuela":_vm.idEscuela},on:{"cerrar":function($event){_vm.mostrarReporteAlumnosxmateria = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { getServerRequest, postServerRequest } from '@/components/globals/services/serverRequest.service';

import { store } from '../../../store/store';

const httpURL = store.getters.httpURL;

export const getCiclosEscuela = async (idEscuela) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/reporte/escuela/${idEscuela}/ciclos`;
    return await getServerRequest(url, config);
}

export const generarReporteAlumnosGrupo = async (idEscuela, idCiclo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }, responseType: "blob" };
    const url = `${httpURL}/reporte/escuela/xlsx/${idEscuela}/${idCiclo}/alumnosxmateria`;
    return await getServerRequest(url, config);
}
<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4">
        <datosEscuelaComponent />
        <br />
        <reportesEscuelaComponent />
      </v-col>
      <v-col cols="12" md="8">
        <configuracionEscuelaComponent />
        <br />
        <administradoresEscuelaComponent />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datosEscuelaComponent from "./datosEscuela.vue";
import administradoresEscuelaComponent from "./administradoresEscuela.vue";
import configuracionEscuelaComponent from "./configuracionEscuela.vue";
import reportesEscuelaComponent from "./reportes/reportesEscuela.vue";
export default {
  name: "escuelaView",
  components: {
    datosEscuelaComponent,
    administradoresEscuelaComponent,
    configuracionEscuelaComponent,
    reportesEscuelaComponent,
  },

  data() {
    return {
      loading: false,
    };
  },
};
</script>

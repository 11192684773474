<template>
  <div>
    <v-card outlined>
      <v-toolbar flat color="grey lighten-5" dense>
        <v-toolbar-title>Reportes</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list flat>
        <v-list-item>
          <v-list-item-action>
            <v-btn
              color="green darken-1"
              x-small
              dark
              @click="mostrarReporteAlumnosxmateria = true"
              ><v-icon small>mdi-file-excel</v-icon></v-btn
            >
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Alumnos por materia</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <alumnosxmateriaComponent
      v-if="mostrarReporteAlumnosxmateria"
      :mostrar="mostrarReporteAlumnosxmateria"
      :idEscuela="idEscuela"
      @cerrar="mostrarReporteAlumnosxmateria = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import alumnosxmateriaComponent from "./alumnosxmateria.vue";

export default {
  name: "reportesEscuelas",
  components: { alumnosxmateriaComponent },
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "colors"]),
  },
  mounted() {
    this.idEscuela = this.$route.params.idEscuela;
  },
  data() {
    return {
      loading: false,
      idEscuela: null,
      mostrarReporteAlumnosxmateria: false,
    };
  },
  methods: {},
};
</script>

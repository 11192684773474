<template>
  <div>
    <v-dialog v-model="mostrar" width="300">
      <v-card>
        <v-toolbar dense dark flat color="primary"
          ><h3>Generar reporte</h3></v-toolbar
        >
        <v-container>
          <v-select
            label="Ciclo"
            v-model="cicloSel"
            :items="ciclos"
            :loading="loading"
            :disabled="loading"
            item-text="nombre"
            return-object
            outlined
            dense
          >
          </v-select>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined small @click="$emit('cerrar')"
            >Cerrar</v-btn
          >
          <v-btn color="primary" small @click="generarReporte()">Generar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getCiclosEscuela,
  generarReporteAlumnosGrupo,
} from "./reportes.service";

export default {
  props: ["idEscuela", "mostrar"],
  computed: {
    ...mapGetters(["sessionToken"]),
  },

  data() {
    return {
      loading: false,
      ciclos: [],
      cicloSel: null,
    };
  },
  mounted() {
    this.cargarCiclos();
  },
  methods: {
    async cargarCiclos() {
      try {
        this.loading = true;
        const serverResponse = await getCiclosEscuela(this.idEscuela);
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else {
          this.ciclos = serverResponse.ciclos;
          if (this.ciclos.length > 0) this.cicloSel = this.ciclos[0];
        }
      } catch (error) {
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },
    async generarReporte() {
      this.loading = true;
      try {
        const serverResponse = await generarReporteAlumnosGrupo(
          this.idEscuela,
          this.cicloSel._id
        );

        const url = window.URL.createObjectURL(serverResponse);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `alumnos_materia_${this.cicloSel.nombre}.xlsx`);

        link.click();
        link.remove();
      } catch (error) {
        console.log(error);
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

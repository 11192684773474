import { getServerRequest, putServerRequest, postServerRequest, deleteServerRequest } from '@/components/globals/services/serverRequest.service';

import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function buscarUsuarios(data, filtro) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    let url = `${httpURL}/usuarios/busqueda`;
    if (filtro) url += `?${filtro}`;
    return await postServerRequest(url, data, config);
}

export async function getDatosUsuarioService(idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuario/${idUsuario}`;
    return await getServerRequest(url, config);
}

export async function activarCuentaUsuario(idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuario/activar/${idUsuario}`;
    return await putServerRequest(url, {}, config);
}

export async function getDatosTabs(idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuario/registro/${idUsuario}`;
    return await getServerRequest(url, config);
}

export async function eliminarUsuarioService(idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuario/${idUsuario}`;
    return await deleteServerRequest(url, config);
}

export async function generarTokenCampus(idUsuario,app,idApp) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuario/${idUsuario}/iniciarSesion`;
    return await postServerRequest(url,{app, idApp, fromMovile:false},config);
}

export async function restablecerContrasenaService(idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuario/restablecerContrasena/${idUsuario}`;
    return await putServerRequest(url, {}, config);
}
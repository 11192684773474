import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"width":"300"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","dark":"","flat":"","color":"primary"}},[_c('h3',[_vm._v("Generar reporte")])]),_c(VContainer,[_c(VSelect,{attrs:{"label":"Ciclo","items":_vm.ciclos,"loading":_vm.loading,"disabled":_vm.loading,"item-text":"nombre","return-object":"","outlined":"","dense":""},model:{value:(_vm.cicloSel),callback:function ($$v) {_vm.cicloSel=$$v},expression:"cicloSel"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('cerrar')}}},[_vm._v("Cerrar")]),_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.generarReporte()}}},[_vm._v("Generar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

  <v-dialog v-model="mostrar" width="600" persistent transition="dialog-bottom-transition">
    <v-card v-if="!mostrarMensaje">
      <v-toolbar color="danger" dense flat dark class="text-h6">Restablecer contraseña</v-toolbar>

      <v-container class="text-center">
        <b>¿Está seguro de restablecer la contraseña del usuario?</b> <br>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary" 
          outlined 
          small 
          :disabled="loading" 
          @click="$emit('cancelar')"
        >Cancelar
        </v-btn>
        <v-btn 
          color="danger" 
          small
          dark
          :loading="loading" 
          @click="restablecerContraseña()">Aceptar</v-btn>
      </v-card-actions>


    </v-card>

    <v-card v-else>
      <v-toolbar color="primary" dense flat dark class="text-h6">Contraseña restablecida</v-toolbar>

      <v-container class="text-center">
        <b>Nueva contraseña: </b> <br>
        <i>{{ this.contrasena }}</i>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary" 
          outlined 
          small 
          :disabled="loading" 
          @click="terminarRestablecerContrasena()"
        >Salir
        </v-btn>
      </v-card-actions>

    </v-card>
  
  </v-dialog>
  
</template>

<script>
import { restablecerContrasenaService } from "./usuarios.service";

export default {
  name: 'restablecerContraseña',
  props: {
    mostrar: { type: Boolean, default: false },
    idUsuario: { type: String, default: '' }
  },
  data () {
    return {
      loading: false,
      mostrarMensaje: false,
      contrasena: null,
    }
  },
  methods: {
    async restablecerContraseña() {

      try {

        this.loading = true;

        const serverResponse = await restablecerContrasenaService(this.idUsuario);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.contrasena = serverResponse.contrasena;
          this.mostrarMensaje = true;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }

    },
    terminarRestablecerContrasena () {
      this.mostrarMensaje = false;
      this.contrasena = null;
      this.$emit('cancelar');
    }

  }
}
</script>
import templateIcons from '../../components/globals/template/icons'
import { appVersion } from '../../components/globals/variables.json'

const state = {
    httpURL: process.env.VUE_APP_httpURL,
    redirect: process.env.VUE_APP_redirect,
    redirectEmpresa: process.env.VUE_APP_redirectEmpresa,
    redirectEscuela: process.env.VUE_APP_redirectEscuela,
    redirectMaster: process.env.VUE_APP_redirectMaster,

    discordURL: process.env.VUE_APP_discordURL,
    imageURL: process.env.VUE_APP_imageURL,
    videoURL: process.env.VUE_APP_videoURL,
    documentsURL: process.env.VUE_APP_documentsURL,

    appName: 'Master',
    app: 'master',
    systemVersion: appVersion,
    ...templateIcons
}

const getters = {
    httpURL: state => {
        return state.httpURL;
    },
    redirect: state => {
        return state.redirect;
    },
    redirectEmpresa: state => {
        return state.redirectEmpresa;
    },
    redirectEscuela: state => {
        return state.redirectEscuela;
    },
    redirectMaster: state => {
        return state.redirectMaster;
    },
    imageURL: state => {
        return state.imageURL;
    },
    discordURL: state => {
        return state.discordURL;
    },
    appName: state => {
        return state.appName;
    },
    app: state => {
        return state.app;
    },
    systemVersion: state => {
        return state.systemVersion;
    },
    actividadArchivoIcon: state => {
        return state.actividadArchivoIcon;
    },
    actividadForoIcon: state => {
        return state.actividadForoIcon;
    },
    actividadEncuestaIcon: state => {
        return state.actividadEncuestaIcon;
    },
    examenIcon: state => {
        return state.examenIcon;
    }
}

export default {
    state,
    getters
}
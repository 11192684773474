<template>
  <div>
    <v-card>
      <v-toolbar color="secondary" dense dark flat class="text-h6"
        >Configuración</v-toolbar
      >
      <v-sheet v-if="cargando" class="pa-3">
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar-two-line@3"
        ></v-skeleton-loader>
      </v-sheet>
      <v-list v-else>
        <v-list-item>
          <v-list-item-avatar
            ><v-icon>mdi-book-open-outline</v-icon></v-list-item-avatar
          >
          <v-list-item-title>Cursos</v-list-item-title>
          <switch-mini
            :active="cursos"
            :loading="loading.cursos"
            @activate="modificarConfiguracion('cursos', false)"
            @deactivate="modificarConfiguracion('cursos', true)"
          />
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            ><v-icon>mdi-format-list-text</v-icon></v-list-item-avatar
          >
          <v-list-item-title>Cátalogos</v-list-item-title>
          <switch-mini
            :active="catalogos"
            :loading="loading.catalogos"
            @activate="modificarConfiguracion('catalogos', false)"
            @deactivate="modificarConfiguracion('catalogos', true)"
          />
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            ><v-icon>mdi-clipboard-list-outline</v-icon></v-list-item-avatar
          >
          <v-list-item-title>Oferta</v-list-item-title>
          <switch-mini
            :active="oferta"
            :loading="loading.oferta"
            @activate="modificarConfiguracion('oferta', false)"
            @deactivate="modificarConfiguracion('oferta', true)"
          />
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            ><v-icon>mdi-format-list-checks</v-icon></v-list-item-avatar
          >
          <v-list-item-title>Instrumentos de evaluación</v-list-item-title>
          <switch-mini
            :active="instrumentos"
            :loading="loading.instrumentos"
            @activate="modificarConfiguracion('instrumentos', false)"
            @deactivate="modificarConfiguracion('instrumentos', true)"
          />
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            ><v-icon>mdi-text-box-check</v-icon></v-list-item-avatar
          >
          <v-list-item-title>Exámenes</v-list-item-title>
          <switch-mini
            :active="examenes"
            :loading="loading.examenes"
            @activate="modificarConfiguracion('examenes', false)"
            @deactivate="modificarConfiguracion('examenes', true)"
          />
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            ><v-icon>mdi-clipboard-list-outline</v-icon></v-list-item-avatar
          >
          <v-list-item-title>Encuestas</v-list-item-title>
          <switch-mini
            :active="encuestas"
            :loading="loading.encuestas"
            @activate="modificarConfiguracion('encuestas', false)"
            @deactivate="modificarConfiguracion('encuestas', true)"
          />
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar
            ><v-icon
              >mdi-file-document-multiple-outline</v-icon
            ></v-list-item-avatar
          >
          <v-list-item-title>Recursos</v-list-item-title>
          <switch-mini
            :active="recursos"
            :loading="loading.recursos"
            @activate="modificarConfiguracion('recursos', false)"
            @deactivate="modificarConfiguracion('recursos', true)"
          />
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar><v-icon>mdi-video</v-icon></v-list-item-avatar>
          <v-list-item-title>Videos</v-list-item-title>
          <switch-mini
            :active="videos"
            :loading="loading.videos"
            @activate="modificarConfiguracion('videos', false)"
            @deactivate="modificarConfiguracion('videos', true)"
          />
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar
            ><v-icon
              >mdi-clipboard-text-clock-outline</v-icon
            ></v-list-item-avatar
          >
          <v-list-item-title>Asistencias</v-list-item-title>
          <switch-mini
            :active="asistencias"
            :loading="loading.asistencias"
            @activate="modificarConfiguracion('asistencias', false)"
            @deactivate="modificarConfiguracion('asistencias', true)"
          />
        </v-list-item>

        <v-list-item>
          <v-list-item-avatar><v-icon>mdi-puzzle</v-icon></v-list-item-avatar>
          <v-list-item-title>Actividades interactivas</v-list-item-title>
          <switch-mini
            :active="interactivos"
            :loading="loading.interactivos"
            @activate="modificarConfiguracion('interactivos', false)"
            @deactivate="modificarConfiguracion('interactivos', true)"
          />
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  modificarConfiguracionService,
  getConfiguracionService,
} from "./escuelas.service";
import switchMini from "../globals/template/widgets/switchMini.vue";

export default {
  name: "configuracionEscuela",
  components: { switchMini },
  computed: {
    ...mapGetters(["imageURL", "sessionToken"]),
  },
  watch: {
    sessionToken(value) {
      if (value) this.getConfiguracion();
    },
  },
  data() {
    return {
      loading: [
        { cursos: false },
        { catalogos: false },
        { oferta: false },
        { instrumentos: false },
        { examenes: false },
        { encuestas: false },
        { recursos: false },
        { videos: false },
        { asistencias: false },
        { interactivos: false },
      ],
      cargando: false,
      idEscuela: null,
      cursos: false,
      catalogos: false,
      oferta: false,
      instrumentos: false,
      examenes: false,
      encuestas: false,
      recursos: false,
      videos: false,
      asistencias: false,
      interactivos: false,
    };
  },
  created() {
    this.idEscuela = this.$route.params.idEscuela;
    if (this.sessionToken) this.getConfiguracion();
  },
  methods: {
    async modificarConfiguracion(key, value) {
      try {
        this.loading[key] = true;
        const data = { [`${key}Activo`]: value };
        const serverResponse = await modificarConfiguracionService(
          this.idEscuela,
          data
        );
        this.loading[key] = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this[key] = serverResponse[`${key}Activo`];
      } catch (error) {
        this.loading[key] = false;
        this.$appErrorMessage();
      }
    },
    async getConfiguracion() {
      try {
        this.cargando = true;
        const serverResponse = await getConfiguracionService(this.idEscuela);
        this.cargando = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.cursos = serverResponse.cursosActivo
            ? serverResponse.cursosActivo
            : false;
          this.catalogos = serverResponse.catalogosActivo
            ? serverResponse.catalogosActivo
            : false;
          this.oferta = serverResponse.ofertaActivo
            ? serverResponse.ofertaActivo
            : false;
          this.instrumentos = serverResponse.instrumentosActivo
            ? serverResponse.instrumentosActivo
            : false;
          this.examenes = serverResponse.examenesActivo
            ? serverResponse.examenesActivo
            : false;
          this.encuestas = serverResponse.encuestasActivo
            ? serverResponse.encuestasActivo
            : false;
          this.recursos = serverResponse.recursosActivo
            ? serverResponse.recursosActivo
            : false;
          this.videos = serverResponse.videosActivo
            ? serverResponse.videosActivo
            : false;
          this.asistencias = serverResponse.asistenciasActivo
            ? serverResponse.asistenciasActivo
            : false;
          this.interactivos = serverResponse.interactivosActivo
            ? serverResponse.interactivosActivo
            : false;
        }
      } catch (error) {
        this.load = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
